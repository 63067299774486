/// <reference path='./../_reference.ts' />
module Apex.UIFrame.Interfaces {

    export interface IUser {
        UserName?: string,
        Email?: string,
        Name?: string,
        LastName?: string,
        Language?: string,
        DisplayName?: string,
        Mobile?: string,
        BirthDate?: Date,
        Password?: string
    }

    export interface IUserCompany {
        Id?: number,
        Code?: string,
        Name?: string,
        Status?: number
    }

    export interface ICompanyInfo {
        Id?: number,
        Code?: string
        Name?: string,
        Status?: number,
        OrgData?: Orginfo,
        OwnerUser?: ERPUserInfo,
    }

    export interface Orginfo {
        Name?: string,
        ShortName?: string,
        RegNumber?: string,
        OrgType?: string,
        NAEC?: string,
        IsVatPayer?: boolean,
        VatSert?: string,
        VatSertIsued?: Date,
        IsExcisePayer?: boolean,
        ExciseSert?: string,
        ExciseSertIsued?: Date,
        JurAddress?: string,
        Address?: string,
        Phone?: string,
        Fax?: string,
        Email?: string,
        Url?: string,
        Bank?: string,
        BankAcc?: string,
        TransactionalAcc?: string,
        CEOPost?: string,
        CEOName?: string,
        HRPost?: string,
        HRName?: string,
        AccountentPost?: string,
        AccountentName?: string,
        CashierPost?: string,
        CashierName?: string,
        RSName?: string,
        RSAddress?: string,
        RSPhone?: string,
        RSHead?: string,
        VatPutAcc?: string,
        VatNoPutAcc?: string,
        VatInvoiceAcc?: string,
        ExchangeRateDiffPlusAcc?: string,
        ExchnageRateDiffMinusAcc?: string,
    }

    export interface ERPUserInfo {
        UserId?: number,
        UserName?: string,
        Email?: string,
        Name?: string,
        LastName?: string,
        LastLoginAttempt?: Date,
        InvalidLoginAttempts?: number,
        Blocked?: boolean,
        CreatedDate?: Date,
        ModifiedDate?: Date,
        LockedDate?: Date,
        PhoneNumber?: string,
        BirthDate?: Date,
        Language?: string,
        EmailConfirmed?: boolean,
        IsSysAdmin?: boolean,

    }

    export interface UserApiKey {
        Key?: string;
        KeyType?: string;
        ExpiryDate?: string;
        Environment?: string;
        Notes?: string;
        CreatedDate?: string;
        CancelledDate?: string;
    }
}