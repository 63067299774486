/// <reference path='../_reference.ts' />
module Apex.UIFrame.Controllers {
    export class Login extends Core.Controller {

        public static $inject = [
            Globals.scope, Globals.rootScope, Globals.location, 'mainService', Globals.localStorage
        ];

        constructor(private $scope: any, private $rootScope: any, private $location: any, private mainService: Services.Main, private $localStorage) {
            super(arguments, Login.$inject);
            $scope.events = this;
            $scope.message = "";
            $scope.loginData = {
                UserName: '',
                Password: '',
                RememberMe: 'False'
            };
            if (typeof $localStorage.lastUser !== "undefined") {
                $scope.loginData.UserName = $localStorage.lastUser;
            }          
        }

        logIn = () => {
            this.mainService.login(this.$scope.loginData).then(resp => {
                if (resp !== null && typeof resp !== "undefined" && typeof resp.statusCode === "undefined") {
                    this.$rootScope.app = {
                        userCredetials: resp
                    }
                    this.$localStorage.lastUser = resp.UserName;
                    this.$location.path('/apex/welcome');
                }
            }, error => {
                console.log(error)
            });
        }
        logOut() {
            this.mainService.logOut();
            this.$location.path('/login');
        }

    }
}
