/// <reference path='../_reference.ts' />
module Apex.UIFrame.Controllers {
    export interface ProfilelScope extends angular.IScope {
        components: {
            companiList: Apex.UIFrame.Interfaces.IUserCompany[];
            Users: Apex.UIFrame.Interfaces.IUser,
            RetryPassword?: string,
            UserID?: number,
            CompanyInfo: Apex.UIFrame.Interfaces.ICompanyInfo,
            EnvApiKeys: Apex.UIFrame.Interfaces.UserApiKey[],

            SUserName: string;
            SPassword: string;
        },

        dynamicPopover: {
            templateUrl: any
        },
        events: {
            showOrHide: Function,
            getUserInfo: Function,
            update: Function,
            profilePage: Function,
            welcome: Function,
            openAddCompanyModal: Function,
            openAddNewCompanyModal: Function,
            addConfirmation: Function,
            cancelConfirmation: Function,
            getCompStatus: Function,
            openModal: Function,
            editCompany: Function,
            updateCompanyInfo: Function,
            InfoCompany: Function,
            ConfirmInvitedCompany: Function,
            getApiKeys: Function,
            newApiKey: Function,
            setApiEnv: Function,
            copyToClipboard: Function,
            cancelApiKey: Function,
            GetCurUser: Function,
            CheckMofUser: Function,
            setCurUser: Function
        },
        companyAddress: string;
        companyName: string;
        companyCode: string;
        companyStatus: string;

        companyBank: string;
        companyisExcisePayer: boolean;
        companyisVatPayer: boolean;
        companyJurAddress: string;
        companyNAEC: string;
        companyOrgType: string;
        companyPhone: string;

        companyLogo: any;

        showMode: boolean;
        passwordFrame: any;
        isChecked: boolean;
        apiKeyEnv: string;
        curCompany: string;
        searchText: string;
    }

    export class Profile extends Core.Controller {
        public static $inject = [
            Globals.scope, Globals.rootScope, Globals.location, Globals.toaster, 'mainService', Globals.localStorage, Globals.state
        ];

        constructor(private $scope: ProfilelScope, private $rootScope: any, private $location: any, private toaster: any,
            private mainService: Services.Main, private $localStorage, private state: any) {
            super(arguments, Profile.$inject);

            $scope.isChecked = false;
            $scope.companyAddress = '';
            $scope.companyName = '';
            $scope.companyStatus = '';
            $scope.searchText = '';
            $scope.companyBank = '';
            $scope.companyisExcisePayer = false;
            $scope.companyisVatPayer = false;
            $scope.companyJurAddress = '';
            $scope.companyNAEC = '';
            $scope.companyOrgType = '';
            $scope.companyPhone = '';
            $scope.companyCode = '';
            $scope.apiKeyEnv = null;
            $scope.curCompany = null;
            $scope.components = {
                Users: null,
                UserID: null,
                companiList: null,
                CompanyInfo: null,
                EnvApiKeys: null,

                SUserName: "",
                SPassword: "",
            }
            $scope.dynamicPopover = {
                templateUrl: 'myPopoverTemplate.html'
            }
            $scope.events = {
                showOrHide: () => {
                    if (!$scope.showMode) {
                        $scope.showMode = true;
                        $scope.passwordFrame.css("display", "");
                        $scope.passwordFrame.animate({
                            height: '120px'
                        }, 400);

                    }
                    else {
                        $scope.showMode = false;
                        $scope.passwordFrame.animate({
                            height: '0'
                        }, 400, () => { $scope.showMode = false; $scope.passwordFrame.css("display", "none"); });
                    }
                },
                editCompany: (code: string) => {
                    $scope.events.updateCompanyInfo(code);
                },
                openModal: (data) => {
                    var dlg = Core.Utils.ModalForm.openModalForm('editCompany.modal.html', EditCompanyModal.getName(), data, Core.Utils.ModalSize.Medium);
                    dlg.result.then(result => {
                        if (result) {
                            this.$scope.events.getUserInfo();
                        }
                    });
                },
                InfoCompany: (code: string) => {
                    this.mainService.FindERPCompanyInfo(code).then(res => {
                        $scope.components.CompanyInfo = res.Result;
                    });
                    var url = Apex.Global.Settings.serviceUrl;
                    $scope.companyLogo = url + "/companies/" + code + "/logo";
                },
                updateCompanyInfo: (code: string) => {
                    this.mainService.FindERPCompanyInfo(code).then(res => {
                        $scope.components.CompanyInfo = res.Result;
                        $scope.events.openModal($scope.components.CompanyInfo);
                    });
                    var url = Apex.Global.Settings.serviceUrl;
                    $scope.companyLogo = url + "/companies/" + code + "/logo";
                },
                getUserInfo: () => {
                    if (!!this.$rootScope.userName) {
                        this.mainService.userInfo(this.$rootScope.usernName).then(result => {
                            if (result && result && result.Result) {
                                $scope.components.Users = result.Result;
                                $scope.components.UserID = result.Result.UserId;
                                if (result.Result.UserId !== null) {
                                    $scope.apiKeyEnv = "MasterEnv";
                                    this.mainService.getCurrentUSerCompanyes(result.Result.UserId).then(result => {
                                        $scope.components.companiList = result.Result;
                                        $scope.isChecked = true;
                                    });
                                }
                            }
                        });
                    } else {
                        this.mainService.chekAuth().then(a => {
                            this.$rootScope.userName = a.UserName;
                            $scope.events.getUserInfo();
                        });
                    }
                },
                update: () => {
                    if ($scope.components.RetryPassword !== $scope.components.Users.Password) {
                        Core.Utils.Helper.safeApply($scope, () => { this.toaster.pop('error', 'შეცდომა', 'პაროლები არ ემთხვევა') });
                        return;
                    }
                    else {
                        this.mainService.updateUser($scope.components.Users).then(resp => {
                            if (resp !== null && typeof resp !== "undefined") {
                                state.go('apex.welcome');
                                Core.Utils.Helper.safeApply($scope, () => { this.toaster.pop('success', 'ყურადღება', 'წარმატებით განახლდა პროფილის მონაცემები') });
                            }
                        }, error => { console.log(error) });
                    }
                },

                profilePage: () => {
                    state.go('secure.profile');
                },

                welcome: () => {
                    state.go('apex.welcome');
                },
                openAddCompanyModal: () => {
                    var dlg = Core.Utils.ModalForm.openModalForm('addcompany.modal.html', 'addCompanyController', null, Core.Utils.ModalSize.Small);
                    dlg.result.then(result => {
                        if (typeof result !== "undefined" && result) {


                        }
                    });
                },
                openAddNewCompanyModal: () => {
                    var dlg = Core.Utils.ModalForm.openModalForm('addcompany.modal.html', 'addCompanyController', null, Core.Utils.ModalSize.Small);
                    dlg.result.then(result => {
                        if (typeof result !== "undefined" && result) {


                        }
                    });
                },

                getCompStatus: (status: number) => {
                    let _status: string;
                    switch (status) {
                        case 1: _status = "აქტიური"; break;
                        case 0: _status = "არის პროექტი"; break;
                        case -1: _status = "შეჩერებული"; break;
                        case -2: _status = "გაუქმებული"; break;
                        case -3: _status = "უარყოფილი"; break;
                    }
                    return _status;
                },

                ConfirmInvitedCompany: (code: string, Confirm?: boolean) => {
                    this.mainService.confirmCompanyInvite(code, Confirm);
                },
                addConfirmation: (event, code: string) => {
                    var confirmationDialog = Core.Utils.ModalForm.openConfirmation("ნამდვილად გსურთ კომპანიის მიბმა?");
                    confirmationDialog.result.then(() => {
                        $scope.events.ConfirmInvitedCompany(code, true);
                        Core.Utils.Helper.safeApply($scope, () => { this.toaster.pop('success', 'ყურადღება', 'კომპანია დაემატა') });
                        $(event.target).parent().html("<span class='fa fa-circle text-success' style='text-align:center; line-height:0.5'></span>");
                    })
                },
                cancelConfirmation: (code: string) => {
                    var confirmationDialog = Core.Utils.ModalForm.openConfirmation("ნამდვილად გსურთ უარყოფა?");
                    confirmationDialog.result.then(() => {
                        $scope.events.ConfirmInvitedCompany(code, false);
                        Core.Utils.Helper.safeApply($scope, () => { this.toaster.pop('success', 'ყურადღება', 'კომპანიის შემოთავაზება უარყოფილია') });
                        $(event.target).parent().html("<span class='fa fa-circle text-accent' style='text-align:center;line-height:0.5'></span>");
                    })
                },
                getApiKeys: () => {
                    if ($scope.apiKeyEnv != null) {
                        mainService.getApiKeys($scope.apiKeyEnv).then(result => {
                            if (result != null)
                                $scope.components.EnvApiKeys = result.Results;
                            else
                                $scope.components.EnvApiKeys = null;
                        });
                    }
                },
                newApiKey: () => {
                    if ($scope.apiKeyEnv != null) {
                        var dlg = Core.Utils.ModalForm.openConfirmation("დავაგენერიროთ ახელი გასაღები", false)
                        dlg.result.then(() => {
                            mainService.newApiKey($scope.apiKeyEnv).then(result => {
                                if (result != null)
                                    $scope.components.EnvApiKeys = result.Results;
                                else
                                    $scope.components.EnvApiKeys = null;
                            });
                        });
                    }
                },
                setApiEnv: (env: string) => {
                    $scope.apiKeyEnv = env;
                },
                copyToClipboard: (data: string) => {
                    window.prompt("კოპირებისთვის დააჭირეთ: Ctrl+C, Enter", data);
                },
                cancelApiKey: (key: string) => {
                    if (key != null) {
                        var dlg = Core.Utils.ModalForm.openConfirmation("გავაუქმოთ გასაღები", false)
                        dlg.result.then(() => {
                            mainService.deleteApiKey(key).then(result => {
                                if (result != null)
                                    $scope.components.EnvApiKeys = result.Results;
                                else
                                    $scope.components.EnvApiKeys = null;
                            });
                        });
                    }
                },
                GetCurUser: (code: string) => {
                    if (code) {
                        mainService.GetCurUser(code).then(res => {
                            this.$scope.components.SUserName = res.curUser.Username;
                            this.$scope.components.SPassword = res.curUser.Password;
                        });
                    }
                },
                CheckMofUser: () => {
                    mainService.CheckMofUser(this.$scope.curCompany, this.$scope.components.SUserName, this.$scope.components.SPassword, true).then(resp => {
                        if (resp != null && resp.Result != null && resp.Result) {
                            Core.Utils.Helper.safeApply($scope, () => { this.toaster.pop('success', 'ყურადღება', 'წარმატებით განახლდა მონაცემები') });
                        } else {
                            Core.Utils.Helper.safeApply($scope, () => { this.toaster.pop('error', 'ყურადღება', 'rs.ge მომხმარებლის შენახვა ვერ მოხერხდა!') });
                        }
                    }, error => { console.log(error) });
                },
                setCurUser: (code: string) => {
                    if (code != null || code != undefined) {
                        mainService.GetCurUser(code).then(res => {
                            $scope.components.SUserName = res.curUser.Username;
                            $scope.components.SPassword = res.curUser.Password;
                            $scope.curCompany = code;

                        });
                    }
                }

            }
            $scope.showMode = false;
            $scope.passwordFrame = jQuery(".passwordFrame");
            $scope.$watch("apiKeyEnv", (oldVal, newVal) => {
                $scope.events.getApiKeys();
            });
            $scope.events.getUserInfo();
        }


        onCompanyChange = (company: Apex.UIFrame.Interfaces.ICompanyInfo) => {
            if (!this.$scope.curCompany && company) {
                this.$scope.events.setCurUser(company.Code);
            }
        }
    }

    export class AddCompany extends Core.Controller {
        public static $inject = [
            Globals.scope, Globals.rootScope, Globals.location, Globals.toaster, 'mainService', Globals.modalInstance, Globals.localStorage, Globals.state
        ];
        constructor(private $scope: any, private $rootScope: any, private $location: any, private toaster: any,
            private mainService: Services.Main, $uibModalInstance, private $localStorage, private state: any) {
            super(arguments, AddCompany.$inject);

            $scope.modalName = 'კომპანიის მიბმა';
            $scope.Company = { code: '', name: '' };
            $scope.RegisterERPCompany = {};
            $scope.findERPCompany = () => {
                if ($scope.Company.code.trim().length == 0) {
                    this.toaster.pop('error', 'ყურადღება', 'შეიყვანეთ კომპანიის კოდი.');
                    return;
                }
                this.mainService.FindERPCompanyInfo($scope.Company.code).then(res => {
                    $scope.Company.name = res.Result.Name;

                    if (res && res.Result) {
                        if (res.Result.Status || res.Result.Status == 0) {
                            this.toaster.pop("warning", "გაფრთხილება", "კომპანია '" + res.Result.Name + "' უკვე არსებობს ბაზაში. მისი სტატუსია '" + this.$scope.getCompanyStatus(res.Result.Status) + "'");
                        }
                    }
                });
            },

                $scope.getCompanyStatus = (status: number) => {
                    let _status: string;
                    switch (status) {
                        case 1: _status = "აქტიური"; break;
                        case 0: _status = "არის პროექტი"; break;
                        case -1: _status = "შეჩერებული"; break;
                        case -2: _status = "გაუქმებული"; break;
                        case -3: _status = "უარყოფილი"; break;
                    }
                    return _status;
                },
                $scope.registerERPCompany = (company: any) => {
                    this.mainService.RegisterERPCompany(company).then(resp => {
                        if (resp != null && resp.Result != null) {
                            Core.Utils.Helper.safeApply(this.$scope, () => { this.toaster.pop('success', 'ყურადღება!', 'მოძებნილი კომპანია წარმატებით დაემატა') });
                        }
                    });
                    $uibModalInstance.close(false);
                },
                $scope.closeForm = () => {
                    $uibModalInstance.close(false);
                }
        }


    }

} 