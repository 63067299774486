/// <reference path='./_reference.ts' />
module Apex.UIFrame {
    'use strict';

    var deps = ['apex.webUI.core', 'ngStorage', 'ui.bootstrap', 'angularjs-dropdown-multiselect', 'ng-context-menu', 'ngScrollbars'];

    var uiFrameModule = new Core.Module('uiFrame', deps, UIFrame);
    var states: Array<Core.Interfaces.IRoute> =
        [
            {
                state: 'apex', url: '/apex', templateUrl: 'apex.uiframe.html', childRoutes: [
                    {
                        state: 'welcome', url: '^/welcome', templateUrl: 'apex.welcome.html'
                    },
                    {
                        state: 'denied', url: '^/denied', template: '<section><div class="section-body contain-lg"><div class="row"><div class="col-lg-12 text-center"><h1><span class="text-xxxl text-light">403 <i class="fa fa-exclamation-circle text-danger"></i></span></h1><h2 class="text-light">Oops! Access denied</h2></div></div></div></section>'
                    }
                ]
            },
            {
                state: 'secure', url: '/access', template: '<ui-view/>', isAbstract: true, childRoutes: [
                    {
                        state: 'login', url: '^/login', controller: Controllers.Login.getName(), templateUrl: 'apex.uiframe.login.html'
                    },
                    {
                        state: 'registration', url: '^/registration', controller: Controllers.Registration.getName(), templateUrl: 'apex.uiframe.registration.html'
                    },
                    {
                        state: 'recovery', url: '^/recovery', controller: Controllers.ResetPassword.getName(), templateUrl: 'apex.uiframe.reset.password.html'
                    },
                    {
                        state: 'recovery1', url: '^/recovery/confirm', controller: Controllers.ResetPasswordConfirm.getName(), templateUrl: 'apex.uiframe.resetPasswordConfirm.html'
                    },
                    {
                        state: 'profile', url: '^/profile', controller: Controllers.Profile.getName(), templateUrl: 'apex.uiframe.profile.html'
                    }
                ]
            }
        ];

    uiFrameModule.regRoutes(states, null, '/welcome');

    export var recursiveRoute = (routes, currentState) => {
        var route;
        _.each(routes, (item: any) => {
            if (item.routeState === currentState) {
                route = item;
            } else if (typeof item.childRoutes !== "undefined" && typeof route === "undefined") {
                var obj: any = recursiveRoute(item.childRoutes, currentState);
                if (obj && obj.routeState === currentState) {
                    route = obj;
                }
            }
        });
        return route;
    };

    uiFrameModule.app.run(function ($transitions) {
        $transitions.onStart({ }, function (trans) {
            
            let to = trans.to();
            let $rootScope = trans.injector().get('$rootScope');
            let $translate = trans.injector().get('$translate');
            let route = recursiveRoute($rootScope.appNavigation, to.name);            
           
            if (route == null)
                return;

            if (!route.isPermitted) {
                event.preventDefault();
                return trans.router.stateService.target('apex.denied');
            }

            var routeFound = false;
            $rootScope.appNavigation.forEach((item: any) => {

                var selectedRoute: any = Apex.UIFrame.Controllers.Main.recursiveSelectedRoute(item.childRoutes);

                if (selectedRoute > 0) {
                    selectedRoute.isSelected = false;
                }

                var innerRoute: any = Apex.UIFrame.Controllers.Main.recursiveState(item.childRoutes, to.name);
                
                if (innerRoute !== undefined) {
                    //this.$scope.currentRouteCaption = route.caption;
                    $rootScope.pageTitle = $translate.instant(innerRoute.caption);
                    innerRoute.isSelected = true;
                    item.isSelected = true;
                    routeFound = true;

                } else {
                    //this.$scope.currentRouteCaption = '';
                    item.isSelected = false;
                }
            });
            if (!routeFound) {
                $rootScope.pageTitle = '';
            }
        });
    }

        //, ($rootScope, $state) => {
        //$rootScope.$on('$stateChangeStart',
        //    (event, toState, toParams, fromState, fromParams) => {
        //        var route = recursiveRoute($rootScope.appNavigation, toState.name);
        //        if (route === undefined || route.isPermitted === undefined)
        //            return;

        //        // TODO: Prevent nvaigaton if no company allowed
        //        //if (($rootScope.userData != null
        //        //    || $rootScope.userData.currentCompany == null)
        //        //    && (route.navigation != null)) {
        //        //    event.preventDefault();
        //        //    $state.go("apex.welcome");
        //        //    return;
        //        //}

        //        if (!route.isPermitted) {
        //            event.preventDefault();
        //            $state.go("apex.denied");
        //        }
        //    });

        //$rootScope
        //    .$on('$stateChangeSuccess',
        //    (event, toState, toParams, fromState, fromParams) => {
        //        //  console.timeEnd("TimerName");
        //    });
        //}
    );
}