/// <reference path='../_reference.ts' />
module Apex.Main.Config {
    'use strict';
    export class Run {
        public static $inject = ['$rootScope', '$state', '$stateParams'];
        constructor($rootScope, $state: angular.ui.IStateService, $stateParams: angular.ui.IStateParamsService) {

            //$rootScope.$on('$stateChangeStart',
            //    (event, toState, toParams, fromState, fromParams) => {
            //        $("#content").css("display", "none");
            //        alert(1);
            //        console.log($("#content"));
            //        // $(".page-loading").removeClass("hidden");
            //    });

            //$rootScope
            //    .$on('$stateChangeSuccess',
            //    (event, toState, toParams, fromState, fromParams) => {
            //        //$(".page-loading").addClass("hidden");
            //        setTimeout(a => {
            //            $("#content").css("display", "block");
            //        }, 500);
            //    });
        }
    }
}
