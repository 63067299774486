/// <reference path='../_reference.ts' />
module Apex.UIFrame.Services {
    'use strict';
    export class Main extends Core.Service {
        getWebRoutes(): angular.IPromise<any> {
            return this.httpGet('/WebUI/Routes');
        }

        login(postData: any): angular.IPromise<any> {
            return this.httpPost('/auth/Credentials', postData);
        }

        logOut(): angular.IPromise<any> {
            return this.httpGet('/auth/logout');
        }

        chekAuth(): angular.IPromise<any> {
            return this.httpGet('/auth');
        }

        register(user) {
            return this.httpPost("/register", user)
        }
        currentUserInfo(): angular.IPromise<any> {
            return this.httpGet("/users/info");
        }
        userInfo(UserName: string): angular.IPromise<any> {
            return this.httpGet("/users/" + UserName + "/info");
        }
        updateUser(User: Apex.UIFrame.Interfaces.IUser): angular.IPromise<any> {
            return this.httpPost("/users/info", {
                UserName: User.UserName,
                Email: User.Email,
                FirstName: User.Name,
                LastName: User.LastName,
                DisplayName: User.DisplayName,
                Language: User.Language,
                PhoneNumber: User.Mobile,
                BirthDate: User.BirthDate,
                NewPassword: User.Password

            });

        }

        resetPassword(email: string): angular.IPromise<any> {
            return this.httpPost("/auth/passwordreset", { Email: email });
        }
        resetPasswordConfirm(Id: string, NewPassword: string): angular.IPromise<any> {
            return this.httpPost("/auth/passwordreset/confirm", { Id: Id, NewPassword: NewPassword });
        }

        currentUserCompanyes(): angular.IPromise<any> {
            return this.httpGet("/users/companies");
        }

        getCurrentUSerCompanyes(userId: number): angular.IPromise<any> {
            return this.httpGet("/users/" + userId + "/companies");
        }
        FindERPCompanyInfo(code: string): angular.IPromise<any> {
            return this.httpGet("/Company/" + code);
        }
        RegisterERPCompany(companyes: Apex.UIFrame.Interfaces.IUserCompany): angular.IPromise<any> {
            return this.httpPost("/companies", { ErpCompany: companyes });
        }
        GetERPCompanyLogo(code: string): angular.IPromise<any> {
            return this.httpGet("/companies/" + code + "/logo");
        }
        updateERPCompany(companyInfo: Apex.UIFrame.Interfaces.ICompanyInfo): angular.IPromise<any> {
            return this.httpPut("/companies", { ErpCompany: companyInfo });

        }

        saveOrgInfo(code: string, data: Apex.UIFrame.Interfaces.Orginfo) {
            return this.httpPost("/Core/Info/Org?Apex-Company-Code=" + code, { OrgInfo: data });
        }

        confirmCompanyInvite(code: string, confirm?: boolean): angular.IPromise<any> {
            return this.httpPatch("/user/companies/" + code + "/invite/confirm", { Confirm: confirm });
        }

        getApiKeys(env: string): angular.IPromise<any> {
            if (env != null)
                return this.httpGet("/json/reply/GetErpApiKeys?Environment=" + env);
            return this.httpGet("/json/reply/GetErpApiKeys");
        }

        newApiKey(env: string): angular.IPromise<any> {
            if (env != null)
                return this.httpPost(Apex.Global.Settings.serviceUrl + "/json/reply/NewErpApiKey", { Environment: env });
            return this.httpPost(Apex.Global.Settings.serviceUrl + "/json/reply/NewErpApiKey", { Environment: 'MasterEnv' });
        }

        deleteApiKey(key: string): angular.IPromise<any> {
            return this.httpDelete(Apex.Global.Settings.serviceUrl + "/json/reply/CancelErpApiKey?Key=" + key);
        }
        GetCurUser(companyCode: string): angular.IPromise<any> {
            return this.httpGet("/RS/CurUser?Apex-Company-Code=" + companyCode);
        } 
        CheckMofUser(companyCode: string, SUserName: any, SPassword: any, Store: boolean): angular.IPromise<any> {
            return this.httpPost("/RS/CheckMofUser?Apex-Company-Code=" + companyCode, { SUserName: SUserName, SPassword: SPassword, Store: Store });
        }

        getCurrentUserActions() {
            return this.httpGet("/Core/Security/User/Actions");
        }

    }
}
