/// <reference path='./../_reference.ts' />
module Apex.UIFrame.Directives {
    "use strict";

    export class UiFloatLabel  {
        restrict = 'AC';
        link() {
            $('.floating-label .form-control').on('keyup change', e => {

                var input = $(e.currentTarget);

                if ($.trim(<string>input.val()) !== '') {
                    input.addClass('dirty').removeClass('static');
                } else {
                    input.removeClass('dirty').removeClass('static');
                }
            });
            $('.floating-label .form-control').each(function () {
    
                var input = $(this);
                if ($.trim(<string>input.val()) !== '') {
                    input.addClass('static').addClass('dirty');
                }
            });
            $('.form-horizontal .form-control').each(function () {
              
                $(this).after('<div class="form-control-line"></div>');
            });
        }

        public static factory() {
            var directive = () => {
                return new UiFloatLabel();
            };
            return directive;
        }
    }


} 