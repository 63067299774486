/// <reference path='../_reference.ts' />
module Apex.UIFrame.Controllers {
    export interface CompanyEditModalScope extends angular.IScope {

        modalName: string;
        companyInfo: Apex.UIFrame.Interfaces.ICompanyInfo;
        companyLogo: any;
        events: {
            closeForm: Function;
            saveUpdatedCompany: Function;
        }
    }

    export class EditCompanyModal extends Core.Controller {
        public static $inject = [
            Globals.scope, Globals.rootScope, Globals.location, Globals.toaster, 'data', 'mainService', Globals.localStorage, Globals.state, Globals.modalInstance,
        ];

        constructor(private $scope: CompanyEditModalScope, private $rootScope: any, private $location: any, private toaster: any, data: any,
            private mainService: Services.Main, private $localStorage, private state: any, $uibModalInstance) {
            super(arguments, EditCompanyModal.$inject);

            $scope.modalName = "კომპანიის რედაქტირება";
            if (data !== null) {
                $scope.companyInfo = data;

                var url = Apex.Global.Settings.serviceUrl;
                this.$scope.companyLogo = url + "/companies/" + $scope.companyInfo.Code + "/logo";
            }
            $scope.events = {
                saveUpdatedCompany: () => {
                    this.mainService.saveOrgInfo(this.$scope.companyInfo.Code, this.$scope.companyInfo.OrgData).then(res => {
                        if (res !== undefined) {
                            Core.Utils.Helper.safeApply(this.$scope, () => { this.toaster.pop('success', 'ყურადღება', 'წარმატებით განახლდა კომპანიის მონაცემები') });
                        }
                    });
                    $uibModalInstance.close(true);
                },
                closeForm: () => {
                    $uibModalInstance.close(false);
                }
            }
        }
    }
} 