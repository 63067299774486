/// <reference path='../_reference.ts' />
module Apex.UIFrame.Controllers {
    export class Main extends Core.Controller {

        public static $inject = [
            Globals.scope, Globals.rootScope, Globals.localStorage, Globals.translate, 'mainService', Globals.state
        ];

        constructor(private $scope: any, private $rootScope: any, private $localStorage, private $translate: any, private mainService: Services.Main, private $state) {
            super(arguments, Main.$inject);
            this.onInit();
            this.initLangs();
            this.pageTitle();
            //this.onStateChange();
        }

        onInit = () => {
            this.$rootScope.app = {
                erpGlobal: {
                    userCredetials: '',
                    modules: []
                },
                initDone: false
            }
            this.$rootScope.usernName = '';
            this.mainService.currentUserInfo().then(result => {
                if (result !== null && typeof result !== "undefined" && result.Result != null) {
                    this.$rootScope.usernName = result.Result.UserName;
                    this.$rootScope.userData = new ERPUserData(<Apex.UIFrame.Interfaces.IUser>result.Result, this.mainService);
                }
            });
        }
        initLangs = () => {
            this.$scope.langs = {
                en: 'English',
                ka: 'ქართული',
                ru: 'Русский'
            };
            this.$scope.selectLang = this.$localStorage.selectedLang || "ka";
            this.$scope.setLang = (langKey, $event) => {
                this.$scope.selectLang = langKey;
                this.$localStorage.langKey = langKey;
                this.$translate.use(langKey);
            };
            if (!!this.$localStorage.langKey) {
                this.$scope.selectLang = this.$localStorage.langKey;
                this.$translate.use(this.$localStorage.langKey);
            }
        }


        public static recursiveState = (routes, currentState) => {
            var route;
            _.each(routes, (item: any) => {
                if (item.routeState === currentState) {
                    route = item;
                } else if (typeof item.childRoutes !== "undefined" && typeof route === "undefined") {
                    var obj: any = Main.recursiveState(item.childRoutes, currentState);
                    if (obj && obj.routeState === currentState) {
                        route = obj;
                    }
                }
            });
            return route;
        };

        public static recursiveSelectedRoute = (routes) => {
            var selectedRoute;
            _.each(routes, (item: any) => {
                if (item.isSelected) {
                    selectedRoute = item;
                } else if (typeof item.childRoutes !== "undefined" && typeof selectedRoute === "undefined") {
                    var obj: any = Main.recursiveSelectedRoute(item.childRoutes);
                    if (obj && obj.isSelected) {
                        selectedRoute = obj;
                    }
                }
            });
            return selectedRoute;
        };

        pageTitle = () => {
            var breakOnNext = false;
            var routeFound = false;
            this.$rootScope.appNavigation.forEach((item: any) => {
                if (breakOnNext)
                    return;
                var route: any = Main.recursiveState(item.childRoutes, this.$state.current.name);
                if (route !== undefined) {
                    this.$scope.currentRouteCaption = route.caption;
                    this.$rootScope.pageTitle = this.$translate.instant(route.caption);
                    route.isSelected = true;
                    item.isSelected = true;
                    breakOnNext = true;
                    routeFound = true;
                   
                } else {
                    this.$scope.currentRouteCaption = '';
                    item.isSelected = false;
                }
            });
            if (!routeFound) {
                this.$rootScope.pageTitle = '';
            }
            breakOnNext = false;
        }
        //onStateChange = () => {
        //    this.$rootScope.$on('$translateChangeSuccess', () => {
        //        if (this.$scope.currentRouteCaption !== '') {
        //            this.$rootScope.pageTitle = this.$translate.instant(this.$scope.currentRouteCaption);
        //        } else {
        //            this.$rootScope.pageTitle = '';
        //        }
        //    });
        //    this.$rootScope.$on('$stateChangeStart',
        //        (event, toState) => {
        //            var routeFound = false;
        //            this.$rootScope.appNavigation.forEach((item: any) => {

        //                var selectedRoute: any = Main.recursiveSelectedRoute(item.childRoutes);

        //                if (selectedRoute > 0) {
        //                    selectedRoute.isSelected = false;
        //                }

        //                var route: any = Main.recursiveState(item.childRoutes, toState.name);
        //                console.log(route);
        //                if (route !== undefined) {
        //                    this.$scope.currentRouteCaption = route.caption;
        //                    this.$rootScope.pageTitle = this.$translate.instant(route.caption);
        //                    route.isSelected = true;
        //                    item.isSelected = true;
        //                    routeFound = true;

        //                } else {
        //                    this.$scope.currentRouteCaption = '';
        //                    item.isSelected = false;
        //                }
        //            });
        //            if (!routeFound) {
        //                this.$rootScope.pageTitle = '';
        //            }
        //            kendo.destroy(document.body);
        //        });
        //}
    }

    export class ERPUserData {
        public userName: string;
        public displayName: string;
        public email: string;
        public avatarUrl: string;
        public userCompanies: Apex.UIFrame.Interfaces.ICompanyInfo[];
        public currentCompany: Apex.UIFrame.Interfaces.ICompanyInfo;
        public userPermissions: string[];

        public changeCompany = (company: Apex.UIFrame.Interfaces.ICompanyInfo) => {
            var httpService = Apex.Core.Utils.Helper.getServiceFromJs("$http");
            var rootScope = Apex.Core.Utils.Helper.getServiceFromJs("$rootScope");

            if (company) {
                httpService.defaults.headers.common["X-Apex-Company-Code"] = company.Code;
            } else {
                delete httpService.defaults.headers.common["X-Apex-Company-Code"];
            }
            var oldCompany = this.currentCompany;
            this.currentCompany = company;

            this.mainService.getCurrentUserActions().then(result => {
                this.userPermissions = result.Result;
                this.changePermissions(rootScope.appNavigation);
                this.checkRoutePermission(rootScope);
                $(document).trigger("apex-company-change", { newCompany: this.currentCompany, oldCopmany: oldCompany });
            });
        }

        private checkRoutePermission(rootScope) {
            var $state = Apex.Core.Utils.Helper.getServiceFromJs("$state");
            var route = Apex.UIFrame.recursiveRoute(rootScope.appNavigation, $state.current.name);
            if (route === undefined) {
                setTimeout(() => {
                    this.checkRoutePermission(rootScope);
                }, 300);
                return;
            }
            if (!route.isPermitted) {
                $state.go("apex.denied");
            }
        }

        private changePermissions(navigationItems) {
            navigationItems.forEach((item) => {
                item.isPermitted = true;
                if (!!item.actionId) {
                    item.isPermitted = (this.userPermissions.indexOf(item.actionId.toLowerCase()) !== -1);
                }
                if (!!item.childRoutes) {
                    this.changePermissions(item.childRoutes);
                    var isPermitted = _.where(item.childRoutes, { isPermitted: true })
                    if (isPermitted.length === 0) {
                        item.isPermitted = false;
                    }
                }
            });
        }



        constructor(user: Apex.UIFrame.Interfaces.IUser, private mainService: Apex.UIFrame.Services.Main) {
            this.userName = user.UserName;
            this.avatarUrl = "./img/avatar.png";
            this.userPermissions = [];
            if (user.DisplayName != null)
                this.displayName = user.DisplayName;
            else
                this.displayName = user.UserName;
            this.email = user.Email;

            var rootScope = Apex.Core.Utils.Helper.getServiceFromJs("$rootScope");

            mainService.currentUserCompanyes().then(result => {
                if (result != null) {
                    this.userCompanies = result.Result;
                    if (this.userCompanies != null && this.userCompanies.length > 0) {
                        this.changeCompany(this.userCompanies[0]);

                        mainService.getCurrentUserActions().then(result => {
                            this.userPermissions = result.Result;
                            this.changePermissions(rootScope.appNavigation);
                            rootScope.app.initDone = true;
                        });
                    } else {
                        rootScope.app.initDone = true;
                    }
                }
            });
        }
    }
}
