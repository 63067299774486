/// <reference path='../_reference.ts' />
module Apex.UIFrame.Controllers {
    export class Registration extends Core.Controller {

        public static $inject = [
            Globals.scope, Globals.rootScope, Globals.location, 'mainService', Globals.localStorage
        ];
        constructor(private $scope: any, private $rootScope: any, private $location: any, private mainService: Services.Main, private $localStorage) {
            super(arguments, Registration.$inject);
            $scope.registrateuser = this.register;
        }

        register = () => {
            var toaster = Core.Utils.Helper.getServiceFromJs(Globals.toaster);
            if (!!this.$scope.registrationForm.$valid) {
                this.mainService.register(this.$scope.model).then(a => {
                    if (!!a.UserId) {
                        toaster.pop('success', "რეგისტრაცია", "ახალი მომხმარებელი შეიქმნა");
                        this.$location.path('/login');
                    }
                })
            }
        }
    }
    export class ResetPassword extends Core.Controller {

        public static $inject = [
            Globals.scope, Globals.rootScope, Globals.location, 'mainService', Globals.localStorage,Globals.state
        ];
        constructor(private $scope: any, private $rootScope: any, private $location: any, private mainService: Services.Main, private $localStorage, private state:any) {
            super(arguments, ResetPassword.$inject);
            $scope.resetPassword = this.resetPassword;
        }
        resetPassword = () => {
            var toaster = Core.Utils.Helper.getServiceFromJs(Globals.toaster);
            this.mainService.resetPassword(this.$scope.Email).then(resp => { 
                toaster.pop('info', "ყურადღება", "გადადით თქვენს ელ-ფოსტაზე გამოგზავნილ ბმულზე");                
            });
            this.$location.path('/login'); 
        }

    }

    export class ResetPasswordConfirm extends Core.Controller {

        public static $inject = [
            Globals.scope, Globals.rootScope, Globals.location,'mainService',  Globals.localStorage, Globals.state
        ];
        constructor(private $scope: any, private $rootScope: any, private $location: any, private mainService: Services.Main, private $localStorage, private state: any) {
            super(arguments, ResetPasswordConfirm.$inject);
            $scope.resetPasswordConfirm = this.resetPasswordConfirm; 
            this.$scope.resetId = $location.search().id;
        }
        resetPasswordConfirm = () => {
            var toaster = Core.Utils.Helper.getServiceFromJs(Globals.toaster);

            if (this.$scope.newPassword == null || this.$scope.newPassword.length == 0) {
                toaster.pop('error', "ყურადღება", "შეიყვანეთ ახალი პაროლი");
                return;
            }

            if (this.$scope.newPassword != this.$scope.newPassword2) {
                toaster.pop('error', "ყურადღება", "პაროლები არ ემთხვევა!");
                return;
            }

           
            this.mainService.resetPasswordConfirm(this.$scope.resetId, this.$scope.newPassword).then(resp => { 
                if (resp != null
                    && typeof resp !== "undefined"
                    && resp.statusCode !== "undefined"
                    && resp.statusCode == 400) {
                    return;
                }
                toaster.pop('success', "ყურადღება", "თქვენ წარმატებით აღადგინეთ პაროლი");
                this.$location.path('/login');  
            });
           
        }

    }

}
